import { Title } from '@solidjs/meta';
import { Button, Errors, Field, Heading, Input, Label, Option, Select, TextField, TextLink } from '@troon/ui';
import { Formspree, formspreeAction } from '@troon/forms';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { IconCircleCheck } from '@troon/icons';
import { NoHydration } from 'solid-js/web';
import { Content } from '../../../components/content';
import { getConfigValue } from '../../../modules/config';

export default function ContactUsPage() {
	const [reason, setReason] = createSignal<string>('');
	const submission = useSubmission(action);

	return (
		<>
			<Title>Contact Us | Troon</Title>

			<Content size="small" class="flex flex-col gap-8">
				<Heading as="h1">Contact Us</Heading>

				<Switch>
					<Match when={submission.result}>
						<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
							<IconCircleCheck class="text-8xl text-brand" />
							<Heading as="h1" size="h2">
								Message received!
							</Heading>
							<p class="text-lg">
								Thanks for contacting us! Our team is reviewing your request and we will get in touch with you shortly.
							</p>
						</div>
					</Match>
					<Match when>
						<Formspree action={action}>
							<Field name="Reason for contact" required>
								<Label>Reason for contact</Label>
								<Select
									onChange={(event) => {
										setReason(event.currentTarget.value);
									}}
								>
									<Option disabled selected class="hidden" />
									<Show when={import.meta.env.DEV}>
										<Option>DEV-ONLY Test submission</Option>
									</Show>
									<Option>Career/Employment</Option>
									<Option>Event Planning</Option>
									<Option>Estore/Sales</Option>
									<Option>Human Resources</Option>
									<Option>Management Services</Option>
									<Option>Payroll</Option>
									<Option>Troon App</Option>
									<Option>Troon Card</Option>
									<Option>Troon Events</Option>
									<Option>Troon Rewards</Option>
									<Option>Website Related</Option>
									<Option>Other</Option>
								</Select>
							</Field>

							<TextField name="Name" required>
								<Label>First and last name</Label>
								<Input autocomplete="name" />
							</TextField>

							<TextField name="email" required>
								<Label>Email address</Label>
								<Input autocomplete="email" inputMode="email" />
							</TextField>

							<TextField name="Street address">
								<Label>Street address</Label>
								<Input autocomplete="street-address" />
							</TextField>

							<div class="flex w-full justify-stretch gap-4">
								<TextField name="City" class="grow">
									<Label>City</Label>
									<Input autocomplete="address-level2" />
								</TextField>
								<TextField name="State" class="grow">
									<Label>State</Label>
									<Input autocomplete="address-level1" />
								</TextField>
								<TextField name="Postal Code" class="grow">
									<Label>Postal Code</Label>
									<Input autocomplete="postal-code" />
								</TextField>
							</div>

							<TextField name="Phone number">
								<Label>Phone number</Label>
								<Input autocomplete="tel" inputMode="tel" />
							</TextField>

							<Switch>
								<Match when={reason() === 'Event Planning'}>
									<>
										<TextField name="Date(s) of event" required>
											<Label>Date(s) of event</Label>
											<Input type="date" />
										</TextField>

										<TextField name="Number of guests">
											<Label>How many guests?</Label>
											<Input inputMode="numeric" type="number" />
										</TextField>

										<TextField name="Desired location(s) City and State">
											<Label>Desired location(s) City and State</Label>
											<Input multiline />
										</TextField>

										<TextField name="Facility you are hoping to host your event">
											<Label>Facility you are hoping to host your event</Label>
											<Input />
										</TextField>
									</>
								</Match>
								<Match when={reason() === 'Troon Card' || reason() === 'Troon Rewards'}>
									<TextField name="Troon Rewards/Card Number">
										<Label>Troon Rewards/Card Number</Label>
										<Input />
									</TextField>
								</Match>
							</Switch>

							<TextField name="Supplemental information" required>
								<Label>Supplemental information</Label>
								<Input multiline />
							</TextField>

							<Errors />
							<Button type="submit" class="w-fit">
								Submit
							</Button>
						</Formspree>
					</Match>
				</Switch>

				<NoHydration>
					<Heading as="h2">About Troon</Heading>
					<p>
						Troon is the world’s largest{' '}
						<TextLink href="https://www.troon.com/contact-us/management-services">
							golf and golf-related hospitality management company
						</TextLink>{' '}
						providing services at 900+ locations. Troon also specializes in homeowner association management, private
						residence clubs, estate management and associated hospitality venues. Troon’s award-winning food and
						beverage division operates and manages more than 600 F&amp;B operations located at golf resorts, private
						clubs, daily fee golf courses and recreational facilities around the world. With properties located in 45+
						states and 35+ countries, Troon’s family of brands includes{' '}
						<TextLink href="/family-of-brands/troon-golf/">Troon Golf</TextLink>,{' '}
						<TextLink href="/family-of-brands/troon-prive/">Troon Privé</TextLink> (the private club operating division
						of Troon), <TextLink href="/family-of-brands/troon-international/">Troon International</TextLink>,{' '}
						<TextLink href="https://www.troon.com/family-of-brands/indigo-sports/">Indigo Sports</TextLink>,{' '}
						<TextLink href="http://www.theiconteam.com">ICON Management</TextLink>,{' '}
						<TextLink href="https://caddiemaster.com/">
							<strong>CADDIE</strong>MASTER
						</TextLink>
						, <TextLink href="https://www.clubup.com/">ClubUp</TextLink>,{' '}
						<TextLink href="https://www.troon.com/family-of-brands/true-club-solutions/">True Club Solutions</TextLink>,{' '}
						<TextLink href="http://www.cliffdrysdale.com">Cliff Drysdale Tennis</TextLink>,{' '}
						<TextLink href="https://www.pbitennis.com/">Peter Burwash International</TextLink>,{' '}
						<TextLink href="https://rfhsd.com/">RealFood Hospitality, Strategy and Design</TextLink>,{' '}
						<TextLink href="https://casaverdegolf.com/">Casa Verde Golf</TextLink>, and{' '}
						<TextLink href="https://eventivesports.com/">Eventive Sports</TextLink>.
					</p>

					<Heading as="h2">Locations</Heading>

					<ul class="grid gap-8 md:grid-cols-2">
						<For each={locations}>
							{(location) => (
								<li class="flex flex-col" itemscope itemtype="https://schema.org/Organization">
									<strong itemprop="name">{location.name}</strong>
									<div class="flex flex-col" itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
										<Show when={location.streetAddress1}>
											{(part) => <span itemprop="streetAddress">{part()}</span>}
										</Show>
										<Show when={location.streetAddress2}>{(part) => <span>{part()}</span>}</Show>
										<span itemprop="addressLocality">
											{[location.city, location.state, location.postalCode].join(', ')}
										</span>

										<Show when={location.country}>{(part) => <span itemprop="addressCountry">{part()}</span>}</Show>
									</div>
									<Show when={location.phone}>
										{(phone) => (
											<span>
												Phone:{' '}
												<TextLink itemprop="telephone" href={`tel:${phone()}`}>
													{phone()}
												</TextLink>
											</span>
										)}
									</Show>
									<Show when={location.fax}>
										{(fax) => (
											<span>
												Fax: <span itemprop="faxNumber">{fax()}</span>
											</span>
										)}
									</Show>
								</li>
							)}
						</For>
					</ul>
				</NoHydration>
			</Content>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'contact-us');

const locations = [
	{
		name: 'Troon – Scottsdale (Corporate Headquarters)',
		streetAddress1: '15044 N. Scottsdale Road, Suite 300',
		city: 'Scottsdale',
		state: 'AZ',
		postalCode: '85254',
		country: 'United States',
		phone: '480.606.1000',
		fax: '480.606.1010',
	},

	{
		name: 'Troon – Jupiter',
		streetAddress1: '1061 E Indiantown Rd., Suite 400',
		city: 'Jupiter',
		state: 'FL',
		postalCode: '33477',
		country: 'United States',
		phone: '561.708.5160',
	},

	{
		name: 'Troon – Irvine',
		streetAddress1: '17875 Von Karman Avenue, Suite 225',
		city: 'Irvine',
		state: 'CA',
		postalCode: '92614',
		country: 'United States',
		phone: '949.800.8180',
	},

	{
		name: 'Troon – Birmingham',
		streetAddress1: '1400 Urban Center Drive, Suite 300',
		city: 'Birmingham',
		state: 'AL',
		postalCode: '35242',
		country: 'United States',
		phone: '205.298.0001',
		fax: '205.970.0304',
	},

	{
		name: 'Troon – Seattle',
		streetAddress1: '2501 15th Ave West',
		city: 'Seattle',
		state: 'WA',
		postalCode: '98119',
		country: 'United States',
		phone: '206.285.2200',
	},

	{
		name: 'Troon – Chicago',
		streetAddress1: '4611 North Ravenswood, Suite 101',
		city: 'Chicago',
		state: 'IL',
		postalCode: '60640',
		country: 'United States',
	},

	{
		name: 'Troon – Jacksonville',
		streetAddress1: '320 N. First Street, Suite 606',
		city: 'Jacksonville Beach',
		state: 'FL',
		postalCode: '32250',
		country: 'United States',
		phone: '904.694.0100',
	},

	{
		name: 'Troon – New Braunfels',
		streetAddress1: '625 Mission Valley Rd.',
		city: 'New Braunfels',
		state: 'TX',
		postalCode: '78132',
		country: 'United States',
		phone: '830.625.5911',
	},

	{
		name: 'Troon – Boston',
		streetAddress1: '63 Chapel Street',
		city: 'Newton',
		state: 'MA',
		postalCode: '02458',
		country: 'United States',
		phone: '617.876.2100',
	},

	{
		name: 'Troon – Reston',
		streetAddress1: '12700 Sunrise Valley Drive, Suite 300',
		city: 'Reston',
		state: 'VA',
		postalCode: '20191',
		country: 'United States',
		phone: '703.761.1444',
	},

	{
		name: 'Troon – Dubai',
		streetAddress1: 'PO Box 502618',
		streetAddress2: 'Loft Building 1-C, Office 210',
		city: 'Dubai Media City',
		state: 'Dubai',
		country: 'United Arab Emirates',
		phone: '971.4.367.1080',
	},

	{
		name: 'Troon – Switzerland',
		streetAddress1: 'Avenue Louis-Casai 18',
		streetAddress2: '1209 Geneva',
		country: 'Switzerland',
		phone: '0041.22.770.5050',
	},

	{
		name: 'Troon – Fort Myers (Icon South)',
		streetAddress1: '11691 Gateway Blvd, Suite 203',
		city: 'Fort Myers',
		state: 'FL',
		postalCode: '33913',
		country: 'United States',
		phone: '239.561.1444',
	},

	{
		name: 'Troon – Bradenton (Icon North)',
		streetAddress1: '5540 State Road 64 East, Suite 220',
		city: 'Bradenton',
		state: 'FL',
		postalCode: '34208',
		country: 'United States',
		phone: '941.747.7261',
	},

	{
		name: 'Troon – Orlando (Icon Central)',
		streetAddress1: '6675 Westwood Blvd, Suite 100',
		city: 'Orlando',
		state: 'FL',
		postalCode: '32821',
		country: 'United States',
	},
];
